:root {
	--white: #ffffff;
	--neutral-colors-333333: #333333;
	--primary-light-blue: #169ce1;
	--primary-black: #0a2432;
	--primary-red: #e91d4a;
	--primary-green: #55c377;
	--primary-orange: #f2a000;
	--primary-grey: #758295;

	--secondary-grey: #cad3df;
	--secondary-light-grey: #cccccc;

	--orange-light: #fff4e2;

	--primary-red-hover: #ff0d42;
	--primary-blue-hover: var(--secondary-blue);

	--primary-red-passed: #d70030;
	--primary-blue-passed: #00589d;

	--input-border-focus-color: #169ce1;
	--input-border-color: var(--secondary-light-grey);

	--tabs-border-color: var(--secondary-light-grey);

	--card-border-color: var(--secondary-light-grey);

	--input-disabled: #f5f6fa;

	--tab-disabled: #f5f6fa;

	--button-active: #f5f6fa;

	--select-item-active: #f5f6fa;

	--background-block-grey: #eceff4;
	--background-block-light-grey: #f5f6fa;
	--background-block: #e7f5ff;
	--background-block-hover: #e7f5ff;

	--background-item-hover: var(--background-block-hover);
	--background-item-hover-2: #f5f6fa;

	--background-body: var(--background-block-grey);

	--bg-badge-success: #e5f5ea;
	--bg-badge-danger: #ffe5eb;
	--bg-badge-danger-light: #ffe6eb;
	--bg-badge-primary-light: #e7f5ff;
	--bg-badge-warning-light: #fff4e2;
	--bg-badge-grey-light: #eceff4;

	--primary-blue: #0e90fb;
	--secondary-blue: #0061af;
	--primary-light-grey: #959595;
	--primary-text-dark: #333333;
}
